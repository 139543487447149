














































































































































































































































































import { getRobotById, GetRobotByIdReq, GetRobotByIdRes, remoteRobot} from "@/api/robot";
import { saveMaintainInfo, getMaintainInfo,  MaintenanceInfoRes, MaintenanceInfoReq, MaintenanceInfo, getRobotInfo, RobotInfoRes, RobotInfo} from "@/api/maintenance";
import { CommonRes }  from "@/api/common";
import MqttClientM from "@/mqtt/mqtt";
import { formatDate, jsonParse } from "@/utils/utils";
import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "@/utils/eventBus";
import { globalData } from "@/utils/config";
import { cid, getTaskResultString } from "@/utils/mqttUtil";
import { startTask, cancelTask, pauseTask, resumeTask, startAnonTask } from "@/utils/taskUtil";
import { powerON, powerOff } from "@/utils/robotCmdUtil";
import {toPlayBack, toVideo, loginRobot, isiOS, stopService} from "@/utils/appUtil";
import { formatsecd2Text } from "@/utils/utils";
import TipDialog from "@/components/TipDialog.vue";
import { getUserInfo, GetUserInfoRes, UserInfo } from "@/api/user";
import { Toast } from "vant";
import { Dialog } from 'vant';
// interface WebAppInterface {
//     toVideo(devId: any) : any;
//     toRecordActivity(devId: any) : any;
// }
// declare var android: WebAppInterface;
@Component({
  filters: {
    dealStatusFilter(status: any) {
      return "locationSuccess";
    },
  },
  components: {
    TipDialog
  },
})
export default class Index extends Vue {
  private robotId: string = "";
  private name: string = "";
  private allDuring: number = 0;
  private allArea: number = 0;
  private showDateTime: boolean = false;
  private timeDate: string = "";
  private currentDate: Date = new Date();
  private minDate: Date = new Date(2000, 0, 1);
  private maxDate: Date = new Date(2035, 10, 1);
  private robot: any = {};
  private routerInfo: any = null;
  private isNewVesion = false;
  private left: number = 0;
  private top: number = 0;
  private dxHeight = 1;
  private dxWidth = 1;
  private orgmapwidth: number = screen.width * 0.85;
  private orgmapheight: number = 410;
  private mapwidth: number = screen.width * 0.85;
  private mapheight: number = 410;
  private angle: number = 0;
  private lastMapWidth: number = 0;
  private lastMapHeight: number = 0;
  private robotMap: string = "";
  private remote_address: string = "";
  private isiOSk: boolean = false;
  private angleMap: number = 0;
  private leftMap: number = 0;
  private canvas: any = null;
  private context: any = null;
  private topMap: number = 0;
  private userId: any = localStorage.getItem('id')
  private faultData: string = ""; //this.$t('no_data').toString()
  private taskFeedBack: any = {}
  private canvasWidth: number = 100;
  private canvasHeight: number = 100;
  private isShowTime:boolean = false
  private location_desc: string = ""
  private canvansOpacity: number = 0;
  private ky: number = 0;
  private zonePointList: any = []
  private isMove: boolean = false
  private curPoint: any
  private rect: any
  private distant: number = 10
  private anonTask: boolean = false
  private Kx :number = 0 
  private Ky :number = 0
  private showDialog: boolean = false
  private companyCode: string = ""
  private mainTainInfo: MaintenanceInfo = {
    robotId: this.robotId,
    maintenanceDate: this.timeDate
  };
  private robotInfo: RobotInfo = {
    lastUsage: "",
    lastMaintenance: ""
  }
  private maintReq: MaintenanceInfoReq = this.mainTainInfo
  created() {
    EventBus.$on("robotDetail", (json: any) => {
      // console.log("msg robotDetail", json);
      if (json) {
        // let json = JSON.parse(msg);
        if (json.cid != undefined) {
          if((cid.start + this.userId).indexOf(json.cid) != -1) { 
            // console.log('json taskResult:', getTaskResultString(json.values.task_result, this));
            Toast(getTaskResultString(json.values.task_result, this))
          } else if (cid.robotInfo.indexOf(json.cid) != -1) {
            this.robot = json;
            this.isNewVesion =  (this.robot.ncu_version> 'MX-1.7.5d-2023.09.22');
            // console.log('robotState', this.robot.ncu_version + " isnew:" + (this.robot.ncu_version> 'MX-1.7.5d-2023.09.22'))
            this.setRobotJson();
            if(this.robot!=undefined && this.robot.location_desc != undefined && this.robot.location_desc.length > 0) {
              localStorage.setItem('location_desc', this.robot.location_desc)
            }
            this.location_desc =  (String)(localStorage.getItem('location_desc'))
          } else if(cid.taskFeedBack.indexOf(json.cid) != -1) {
            this.taskFeedBack = json;
            // console.log('feedBack', json)
          } else if((cid.anonTask + this.userId).indexOf(json.cid) != -1) {
            // this.taskFeedBack = json;
            //console.log('anonTask', json)
            Toast(getTaskResultString(json.values.task_result, this))
          } else if (cid.routerInfo.indexOf(json.cid) != -1) {
            this.routerInfo = json;
            this.routerInfo.router_state = 0
            // console.log('routerInfo', this.routerInfo)
            // console.log('routerInfo', (this.routerInfo && !this.routerInfo.is_robot_on && this.routerInfo.router_state >= 0) + ' isrobot:' +(this.routerInfo && this.routerInfo.is_robot_on))
          }
        } 
      }
    });
    document.addEventListener('pause', function() {  
      console.log('addEventListener: pause');
    });
    console.log(this.$route.params);
    this.name = this.$route.params.robotName;
    this.robotId = this.$route.params.robotId;
    if (this.robotId == undefined || this.robotId == null || this.robotId.length == 0) {
      this.$router.go(-1);
      return
    }
    MqttClientM.getInstance().subscribeRobot(this.robotId)
    MqttClientM.getInstance().subscribeResponse(this.robotId)
    MqttClientM.getInstance().subscribeFeedback(this.robotId)
    MqttClientM.getInstance().subscribeRouterInfo(this.robotId)
    this.getRobotDetail(this.robotId);
    this.isiOSk = isiOS();
    this.getMaintainInfoK();
    this.getRobotInfo();
    console.log('canvas AAAAAAAA')
  }
  dealBatteryIcon(robot: any) {
    if (robot != null && robot.battery_status != null && robot.battery_status.power_supply_status != 0 && robot.battery_status.power_supply_status != 3) { // info.battery_status.power_supply_status != POWER_SUPPLY_STATUS_NORMAL && info.battery_status.power_supply_status != POWER_SUPPLY_STATUS_DISCONNECT
      return require("@/assets/img/battery_charge.png");
    } else {
      return require("@/assets/img/battery.png");
    }
  }
  dealWifiIcon (robot: any) {
    // if (robot != null && robot.battery_status != null && robot.battery_status.current > 0) {
    //   return require("@/assets/img/battery_charge.png");
    // } else {
    //   return require("@/assets/img/battery.png");
    // }
    if ( robot.signal_quality_wifi > 80 ) {
      return require("@/assets/img/wifi_4.png");
    } else if (robot.signal_quality_wifi <= 80 && robot.signal_quality_wifi > 55) {
      return require("@/assets/img/wifi_3.png");
    } else if (robot.signal_quality_wifi <= 55 && robot.signal_quality_wifi > 30) {
     return require("@/assets/img/wifi_2.png");
    } else if (robot.signal_quality_wifi <= 30 && robot.signal_quality_wifi > 0) {
      return require("@/assets/img/wifi_1.png");
    } else if (robot.signal_quality_wifi <= 0) {
      return require("@/assets/img/wifi_0.png");
     }
  }
   deal4GIcon(robot: any) {
    // if (robot != null && robot.battery_status != null && robot.battery_status.current > 0) {
    //   return require("@/assets/img/battery_charge.png");
    // } else {
    //   return require("@/assets/img/battery.png");
    // }
    if ( robot.signal_quality_4g > 75 ) {
      return require("@/assets/img/4g_3.png");
    }  else if (robot.signal_quality_4g <= 75 && robot.signal_quality_4g > 30) {
     return require("@/assets/img/4g_2.png");
    } else if (robot.signal_quality_4g <= 30 && robot.signal_quality_4g > 0) {
      return require("@/assets/img/4g_1.png");
    } else if (robot.signal_quality_4g <= 0) {
      return require("@/assets/img/4g_0.png");
     }
  }
  private async getUserInfo() {
     const res: GetUserInfoRes = await getUserInfo();
      if (res.code == 0) {
        const userinfo2: UserInfo = res.user;
        this.companyCode = userinfo2.companyCode;
      }
  }
  mounted() {
     this.initCanvas() 
     this.getUserInfo()
    // this.$nextTick(() => {
    //   this.initCanvas() 
    // })
  }
  private showAwakeSleepDialog() {
    this.showDialog = true;
  }
  private confirmEvent(){
    this.showDialog = false;
    this.awakeOrSleep();
  }
  private cancelEvent(){
     this.showDialog = false;
  }
  private initCanvas() { 
    this.canvas = document.getElementById('mycanvas') as HTMLCanvasElement// document.getElementById('mycanvas');//document.getElementById('mycanvas')
    console.log('canvas', this.canvas + " this.canvasWidth:" + this.canvasWidth + " this.canvasHeight:" + this.canvasHeight)
    this.context = this.canvas.getContext('2d')
    // this.context.fillStyle = 'rgba(255, 255, 255, 0)'
    this.context.lineWidth = 0.5555
    this.context.lineHeight =1
    this.context.shadowBlur = 1
  }
  handleTouchStart (event: any) {
    // 处理手指点击事件
    // 你可以在这里获取手指的坐标等信息
    this.rect = this.canvas.getBoundingClientRect();
    const touch = event.touches[0]; // Get the first touch point
    const x = touch.clientX - this.rect.left; // X coordinate of the touch
    const y = touch.clientY - this.rect.top; // Y coordinate of the touch
    if (this.zonePointList != undefined && this.zonePointList.length > 0) {
      for (let i = this.zonePointList.length - 1 ; i >= 0; i--) {
        let temp_point_list = this.zonePointList[i]
        console.log('handleTouchStart', event + " x:" + x + " y:" + y + " length:" + this.zonePointList.length + ' temp_point_list:' + JSON.stringify(temp_point_list))
        for (let j = 0; j < temp_point_list.length; j++) {
          let point = temp_point_list[j]
          console.log( 'handleTouchStart', "x:" + x + " y:" + y + " point:" + JSON.stringify(point) + "i：" + i + " j:" + j)
          if( point.x - 15 < x && x < point.x + 15  && point.y - 15 < y && y < point.y + 15 ) {
            this.isMove = true
            this.curPoint = point
            break
          }
        }
        if(this.isMove) {
          break
        }
      }
    }
    event.preventDefault()
    // for (let i = 0 ; i < this.zonePointList.length; i++) {
    //   let temp_point_list = this.zonePointList[i]
    //   for (let j = 0; j < temp_point_list.length; i++) {
    //     let point = temp_point_list[j]
    //     console.log( 'handleTouchStart', "x:" + x + " y:" + y + " point:" + JSON.stringify(point) + "i：" + i + " j:" + j)
    //     if( point.x - 10 < x && x < point.x + 10  && point.y - 10 < y && y < point.y + 10 ) {
    //       this.isMove = true
    //       this.curPoint = point
    //       break
    //     }
    //   }
    // }
    // console.log('handleTouchStart', event + " x:" + x + " y:" + y + " length:" + this.zonePointList.length)
  }
  handleTouchMove (event: any) {
    // 处理手指拖动事件
    // 你可以在这里获取手指的坐标等信息，并实现相应的拖动逻辑
     const touch = event.touches[0]; // Get the first touch point
    const x = touch.clientX - this.rect.left; // X coordinate of the touch
    const y = touch.clientY - this.rect.top; // Y coordinate of the touch
    console.log('handleTouchMove', event + "x:" + x + " y:" + y)
    if (this.isMove) {
      this.curPoint.x = x
      this.curPoint.y = y
      this.drawFrame()
    }
    event.preventDefault()
  }
  handleTouchEnd (event: any) {
    // 处理手指松开事件
    // 你可以在这里进行一些清理工作或者触发其他操作
    console.log('handleTouchEnd', event)
    this.isMove = false
    event.preventDefault()
  }
  private setFrame () {
    this.anonTask = true
    // let x1 = 70.5 + this.distant
    // let y1 = 70.5 + this.distant
    // let pointList: any = []
    // pointList.push({x: x1, y: y1})
    // pointList.push({x: x1, y: y1 + 50})
    // pointList.push({x: x1 + 50, y: y1 + 50})
    // pointList.push({x: x1 + 50, y: y1})
    // this.zonePointList.push(pointList)
    const minHw = this.mapwidth > this.mapheight? (this.mapheight / 3) : (this.mapwidth / 3)
    let fmWidth = this.mapwidth > this.mapheight? (this.mapheight / 3 - this.distant) : (this.mapwidth / 3 - this.distant)
    //let fmHight = this.mapheight / 3 - this.distant
    let x1,y1
    if(this.angleMap == 90) {
       x1 = this.mapheight / 2 - fmWidth / 2
       y1 = this.mapwidth / 2 - fmWidth / 2
    } else {
       x1 = this.mapwidth / 2 - fmWidth / 2
       y1 = this.mapheight / 2 - fmWidth / 2
    }
    let dis = minHw > 20 ? 20: minHw
    if ((fmWidth < dis && fmWidth > 0) || (fmWidth > -dis && fmWidth < 0)) {
      fmWidth = minHw
    }
    if(this.angleMap == 90) {
      if (y1 + fmWidth > this.mapwidth - dis || x1 + fmWidth > this.mapheight - dis || y1 + fmWidth < dis || x1 + fmWidth < dis) {
          alert(this.$t('nomore').toString())
          return
      }
    } else {
      if(y1 + fmWidth > this.mapheight - dis || x1 + fmWidth > this.mapwidth - dis || y1 + fmWidth < dis || x1 + fmWidth < dis) {
        alert(this.$t('nomore').toString())
        return
      }
    }
    
    let pointList: any = []
    pointList.push({x: x1, y: y1})
    pointList.push({x: x1, y: y1 + fmWidth})
    pointList.push({x: x1 + fmWidth, y: y1 + fmWidth})
    pointList.push({x: x1 + fmWidth, y: y1})
    this.zonePointList.push(pointList)
    this.drawFrame()
    this.distant = this.distant + 15
    this.setTouchListener();
  }
  private clearFrame () {
     this.zonePointList.length = []
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.anonTask = false
    this.distant = 0
    this.removeTouchListener();
  }
  private drawMapFrame () {
      this.context.strokeStyle = 'red'
      this.context.beginPath()
      let w = this.mapwidth
      let h = this.mapheight
      if(this.angleMap == 90) {
        w = this.mapheight
        h = this.mapwidth
      }
      this.context.moveTo(2, 2)
      this.context.lineTo(2, h - 2)
      this.context.lineTo(w - 2, h - 2)
      this.context.lineTo(w - 2, 2)
      this.context.lineTo(2, 2)
      this.context.stroke()
   }
  private drawFrame () {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.strokeStyle = '#12df89'
    this.context.fillStyle = 'blue'
    this.canvansOpacity = 1
    this.context.beginPath()
    if  (this.zonePointList!= undefined && this.zonePointList.length > 0) {
      for (let i = 0; i < this.zonePointList.length; i++) {
        this.context.beginPath()
        let tempPointList = this.zonePointList[i]
        for (let j = 0; j < 4; j++) {
            if(j == 0) {
              this.context.moveTo(tempPointList[j].x, tempPointList[j].y)
            } else {
              this.context.lineTo(tempPointList[j].x, tempPointList[j].y)
              if (j == 3){
                this.context.lineTo(tempPointList[0].x, tempPointList[0].y)
            }
          }
        }
        this.context.stroke()
        this.context.beginPath()
        for (let j = 0; j < 4; j++) {
          this.context.moveTo(tempPointList[j].x, tempPointList[j].y)
          this.context.arc(tempPointList[j].x, tempPointList[j].y,2,0,2*Math.PI)
        } 
        this.context.fill()
      }
      this.drawMapFrame()
    }

    // this.context.beginPath()
    // //this.context.arc(95,50,40,0,2*Math.PI);
    // this.context.moveTo(50.5, 50.5)
    // this.context.lineTo(50.5, 100.5)
    // this.context.lineTo(100.5, 100.5)
    // this.context.lineTo(100.5, 50.5)
    // this.context.lineTo(50.5, 50.5)
    // this.context.stroke()
    // this.context.beginPath()
    // this.context.moveTo(50.5,50.5)
    // this.context.arc(50.5,50.5,2,0,2*Math.PI)
    // this.context.moveTo(50.5, 100.5)
    // this.context.arc(50.5,100.5,2,0,2*Math.PI)
    // this.context.moveTo(100.5, 100.5)
    // this.context.arc(100.5, 100.5,2,0,2*Math.PI)
    // this.context.moveTo(100.5, 50.5)
    // this.context.arc(100.5, 50.5,2,0,2*Math.PI)
    // this.context.fill()
  }
   private setText(e: any) {
    return formatsecd2Text(e)
  }
  
  private setTouchListener() {
    this.canvas.addEventListener('touchstart', this.handleTouchStart);
    this.canvas.addEventListener('touchmove', this.handleTouchMove);
    this.canvas.addEventListener('touchend', this.handleTouchEnd);
  }
  private removeTouchListener() {
    this.canvas.removeEventListener('touchstart', this.handleTouchStart);
    this.canvas.removeEventListener('touchmove', this.handleTouchMove);
    this.canvas.removeEventListener('touchend', this.handleTouchEnd);
  }
  private async getMaintainInfoK() {
    let res: MaintenanceInfoRes = await getMaintainInfo(this.robotId);
    if(res.code === 0) {
      this.mainTainInfo = res.maintain
      this.maintReq = this.mainTainInfo
      if (this.mainTainInfo.maintenanceDate != undefined) {
        this.timeDate = this.mainTainInfo.maintenanceDate
      } else {
        this.timeDate = ""
      }
    }
  }
    private async getRobotInfo() {
    let res: RobotInfoRes = await getRobotInfo(this.robotId);
    if(res.code === 0) {
      this.robotInfo = res.robotInfo
    }
  }
  private async setMaintainInfoK(itemData1:string){
    this.maintReq.maintenanceDate = itemData1
    this.maintReq.robotId = this.robotId
    let res: CommonRes = await saveMaintainInfo(this.maintReq);
    if(res.code === 0) {
       alert(this.$t('setup_successful'))
       this.timeDate = itemData1
    }
  }
  private toVideoClick() {
    toVideo(this.robot.ip_camera_id)// a96109eff8655e9e   7b5a0300edddb801
  }
  private toastText() {
    if (this.robot&& this.robot.location_desc.length > 0) {
      Toast(this.robot.location_desc);
    }
  }
  private toTaskList() {
    localStorage.setItem("refresh", "true");
    this.$router.push({
      path: "/work",
      name: "Work",
      params: {
        robotName: this.name,
      },
    });
  }
  private ellipsis (value: string): string {
      if (!value) return ''
      if (value.length > 5) {
        return value.slice(0,5) + '...'
      }
      return value
  }
  private toPlayBackclick (){
    toPlayBack(this.robot.ip_camera_id)
  }
  private showTime () {
    this.isShowTime = !this.isShowTime
  }
  private remoteconnect (){
    Dialog.confirm({
    title: this.$t("remoteconnectTitle").toString(),
    confirmButtonText:this.$t("determine").toString(),
    cancelButtonText:this.$t("no_con").toString(),
    message:this.$t("remoteconnectTip").toString()})
      .then(() => {
        // on confirm
        loginRobot(this.robot.remote_address, this.companyCode);
        this.setRemoteRobot(this.robotId);
    })
      .catch(() => {
        // on cancel
    });
  }
  private setTaskState(robotState: number) {
    switch (robotState){
      case 0 :{
        this.startTask()
        break
      }
      case 2 :{
        this.pauseTask()
        break
      }
      case 3 :{
        this.resumeTask()
        break
      }
      default: {
        this.cancelTask()
      }
    }
  }
   private cancleTask() {
    this.cancelTask()
  }
  private startTask () {
    // let startTask: StartTaskBean = {
    //   op: "call_service",
    //   service: "/start_task",
    //   cid: cid.start + this.userId,
    //   args: {
    //     map_name: this.robot.map_name,
    //     task_name: this.robot.task_name,
    //   }
    // }
    console.log('startTask AAAAAA' )
    if(this.anonTask) {
      if (this.zonePointList == undefined || this.zonePointList.length == 0) {
        this.anonTask = false
        return
      }
      let tempZoneList:any = []
      for(let i = 0; i < this.zonePointList.length; i++) {
        let pointList = this.zonePointList[i]
        let temp_points = []
        for (let j = 0; j < pointList.length; j ++) {
          let point = pointList[j]
          let temp_point: any = {}
          // let temp_x = point.x
          if (this.angleMap !== 90) {
            temp_point.x = point.x * this.Kx
            temp_point.y = point.y * this.Ky
          } else {
            temp_point.x = point.y * this.Kx
            temp_point.y = (this.canvasWidth - point.x) * this.Ky // temp_point.y = (this.canvasHeight - point.x) * this.Ky
          }
          temp_point.theta = 0
          temp_points.push(temp_point)
          console.log('anonTask AAA',  "this.Kx:" + this.Kx + " Ky:" + this.Ky + ' mapheight:' + this.mapheight + ' mapwidth:' +  this.mapwidth + ' this.canvasHeight:' + this.canvasHeight + ' canvasWidth:' + this.canvasWidth + ' temp_points:' + JSON.stringify(temp_points) + " point:" + JSON.stringify(point))
        }
        let sub_task: any = {}
        //sub_task.arc_segment_list = []
        // sub_task.clean_mode = 0
        // sub_task.index = 0
        sub_task.pose_list = temp_points
        // sub_task.repeat_time = 1
        sub_task.task_mode = 6
        tempZoneList.push(sub_task)
      }
      console.log('anonTask BBBb', tempZoneList)
      startAnonTask(tempZoneList, this.robot.map_name, this.robotId, this.userId)
      return
    }
    // MqttClientM.getInstance().publishMessage(topic.cmdTopic + this.robotId,  JSON.stringify(startTask))
    startTask(this.userId, this.robot.map_name,  this.robot.task_name, this.robotId)
  }
  private resumeTask () {
    resumeTask(this.userId, this.robotId)
  }
  private cancelTask () {
    cancelTask(this.userId, this.robotId)
  }
  private pauseTask () {
    pauseTask(this.userId, this.robotId)
  }
  private async setRemoteRobot(robotId: string) {
    let req: GetRobotByIdReq = {
      robotId: robotId,
    };
    let res: GetRobotByIdRes = await remoteRobot(req);
    console.log(res);
  }
  private geterrmsg (errorCode: number) {
    let errmsg:string = ''
    errmsg = this.$t('error_'+ errorCode).toString()
    return  errmsg;
  }
  private geterrmsg2 (errorCode: number) {
      let errmsg:string = ''
      if (errorCode === 0) {
        errmsg = this.$t('normal').toString()
      } else if (errorCode === 101) {
        errmsg = this.$t('Single_chip_no_user').toString()
      } else if (errorCode === 102) {
        errmsg = this.$t('other_errors').toString()
      } else if (errorCode === 103) {
        errmsg = this.$t('single_chip_errors').toString()
      } else if (errorCode === 104) {
        errmsg = this.$t('meter_no_response').toString()
      } else if (errorCode === 105) {
        errmsg = this.$t('meter_other_anomalies').toString()
      } else if (errorCode === 106) {
        errmsg = this.$t('meter_data_abnormity').toString()
      } else if (errorCode === 107) {
        errmsg = this.$t('ui_no_response').toString()
      } else if (errorCode === 108) {
        errmsg = this.$t('ui_other_anomalies').toString()
      } else if (errorCode === 109) {
        errmsg = this.$t('ui_data_abnormity').toString()
      } else if (errorCode === 201) {
        errmsg = this.$t('ui_Control_Module_one').toString()
      } else if (errorCode === 202) {
        errmsg = this.$t('ui_Control_Module_two').toString()
      } else if (errorCode === 203) {
        errmsg = this.$t('ui_error_one').toString()
      } else if (errorCode === 204) {
        errmsg = this.$t('ui_error_two').toString()
      } else if (errorCode === 205) {
        errmsg = this.$t('ui_error_three').toString()
      } else if (errorCode === 206) {
        errmsg = this.$t('ui_error_four').toString()
      } else if (errorCode === 207) {
        errmsg = this.$t('ui_error_five').toString()
      } else if (errorCode === 208) {
        errmsg = this.$t('ui_error_six').toString()
      } else if (errorCode === 209) {
        errmsg = this.$t('ui_error_seven').toString()
      } else if (errorCode === 210) {
        errmsg = this.$t('ui_error_eigth').toString()
      } else if (errorCode === 211) {
        errmsg = this.$t('ui_error_nine').toString()
      } else if (errorCode === 212) {
        errmsg = this.$t('ui_error_ten').toString()
      } else if (errorCode === 213) {
        errmsg = this.$t('ui_error_eleven').toString()
      } else if (errorCode === 214) {
        errmsg = this.$t('ui_error_Twelve').toString()
      } else if (errorCode === 215) {
        errmsg = this.$t('Abnormal_probe_one_low').toString()
      } else if (errorCode === 216) {
        errmsg = this.$t('Abnormal_probe_one_higth').toString()
      } else if (errorCode === 217) {
        errmsg = this.$t('Abnormal_probe_two_low').toString()
      } else if (errorCode === 218) {
        errmsg = this.$t('Abnormal_probe_two_higth').toString()
      } else if (errorCode === 110) {
        errmsg = this.$t('single_chip_com_error').toString()
      } else if (errorCode === 111) {
        errmsg = this.$t('single_chip_com_error_other').toString()
      } else if (errorCode === 112) {
        errmsg = this.$t('single_chip_com_error_data').toString()
      } else if (errorCode === 113) {
        errmsg = this.$t('imu_no_response').toString()
      } else if (errorCode === 114) {
        errmsg = this.$t('imu_other_anomalies').toString()
      } else if (errorCode === 115) {
        errmsg = this.$t('imu_data_abnormity').toString()
      } else if (errorCode === 116) {
        errmsg = this.$t('Handle_exception').toString()
      } else if (errorCode === 119) {
        errmsg = this.$t('com_no_find').toString()
      } else if (errorCode === 120) {
        errmsg = this.$t('com_no_data').toString()
      } else if (errorCode === 122) {
        errmsg = this.$t('six_line_no_find').toString()
      } else if (errorCode === 123) {
        errmsg = this.$t('six_line_no_data').toString()
      } else if (errorCode === 124) {
        errmsg = this.$t('Horizontal_radar_no_find').toString()
      } else if (errorCode === 125) {
        errmsg = this.$t('Horizontal_radar_no_data').toString()
      } else if (errorCode === 126) {
        errmsg = this.$t('Oblique_downward_radar_no_find').toString()
      } else if (errorCode === 127) {
        errmsg = this.$t('Oblique_downward_radar_no_data').toString()
      } else if (errorCode === 134) {
        errmsg = this.$t('Left_drive_control_exception').toString()
      } else if (errorCode === 135) {
        errmsg = this.$t('Left_Driver_Contact_Abnormality').toString()
      } else if (errorCode === 136) {
        errmsg = this.$t('Left_Driver_Overflow').toString()
      } else if (errorCode === 137) {
        errmsg = this.$t('Right_Driver_Control_Exception').toString()
      } else if (errorCode === 138) {
        errmsg = this.$t('Right_Driver_Contact_Abnormality').toString()
      } else if (errorCode === 139) {
        errmsg = this.$t('Right_Driver_Overcurrent').toString()
      } else if (errorCode === 140) {
        errmsg = this.$t('Left_push_rod_not_returned_to_position').toString()
      } else if (errorCode === 141) {
        errmsg = this.$t('Left_push_rod_is_not_put_in_place').toString()
      } else if (errorCode === 142) {
        errmsg = this.$t('right_push_rod_not_returned_to_position').toString()
      } else if (errorCode === 143) {
        errmsg = this.$t('right_push_rod_is_not_put_in_place').toString()
      } else if (errorCode === 150) {
        errmsg = this.$t('Front_Camera_no_Find_Device').toString()
      } else if (errorCode === 151) {
        errmsg = this.$t('Front_camera_has_no_data').toString()
      } else if (errorCode === 152) {
        errmsg = this.$t('Back_Camera_no_Find_Devices').toString()
      } else if (errorCode === 153) {
        errmsg = this.$t('Back_camera_has_no_data').toString()
      } else if (errorCode === 160) {
        errmsg = this.$t('Left_drive_overcurrent').toString()
      } else if (errorCode === 161) {
        errmsg = this.$t('Left_drive_overload').toString()
      } else if (errorCode === 162) {
        errmsg = this.$t('Left_motor_feedback_signal_error').toString()
      } else if (errorCode === 163) {
        errmsg = this.$t('Left_drive_overvoltage').toString()
      } else if (errorCode === 164) {
        errmsg = this.$t('Left_motor_undervoltage').toString()
      } else if (errorCode === 165) {
        errmsg = this.$t('Left_drive_overheating').toString()
      } else if (errorCode === 166) {
        errmsg = this.$t('Failure_to_start_left_motor').toString()
      } else if (errorCode === 167) {
        errmsg = this.$t('Left_Driver_EEP_Data_Error').toString()
      } else if (errorCode === 168) {
        errmsg = this.$t('Left_motor_overheating').toString()
      } else if (errorCode === 169) {
        errmsg = this.$t('Left_motor_overspeed').toString()
      } else if (errorCode === 170) {
        errmsg = this.$t('Left_Drive_ENCODE_Error').toString()
      } else if (errorCode === 171) {
        errmsg = this.$t('Initial_operation_of_left_motor_prohibited').toString()
      } else if (errorCode === 172) {
        errmsg = this.$t('Left_motor_external_stop').toString()
      } else if (errorCode === 173) {
        errmsg = this.$t('Left_Driver_Hall_Sequence_Error').toString()
      } else if (errorCode === 174) {
        errmsg = this.$t('Left_Driver_Communication_Instruction_Abnormal').toString()
      } else if (errorCode === 175) {
        errmsg = this.$t('Left_Driver_Parameter_Setting_Error').toString()
      } else if (errorCode === 176) {
        errmsg = this.$t('Right_Driver_Overcurrent').toString()
      } else if (errorCode === 177) {
        errmsg = this.$t('Right_Driver_Overload').toString()
      } else if (errorCode === 178) {
        errmsg = this.$t('Right_motor_feedback_signal_error').toString()
      } else if (errorCode === 179) {
        errmsg = this.$t('Right_Driver_Overvoltage').toString()
      } else if (errorCode === 180) {
        errmsg = this.$t('Right_motor_undervoltage').toString()
      } else if (errorCode === 181) {
        errmsg = this.$t('Right_Horse_Driver_Overheating').toString()
      } else if (errorCode === 182) {
        errmsg = this.$t('Failure_of_startup_of_right_motor').toString()
      } else if (errorCode === 183) {
        errmsg = this.$t('Right_Driver_EEP_Data_Error').toString()
      } else if (errorCode === 184) {
        errmsg = this.$t('Right_motor_overheating').toString()
      } else if (errorCode === 185) {
        errmsg = this.$t('Right_motor_overspeed').toString()
      } else if (errorCode === 186) {
        errmsg = this.$t('Right_Driver_ENCODE_Error').toString()
      } else if (errorCode === 187) {
        errmsg = this.$t('Initial_operation_of_right_motor_prohibited').toString()
      } else if (errorCode === 188) {
        errmsg = this.$t('Right_motor_external_stop').toString()
      } else if (errorCode === 189) {
        errmsg = this.$t('Right_Driver_Hall_Sequence_Error').toString()
      } else if (errorCode === 190) {
        errmsg = this.$t('Right_Driver_Communication_Instruction_Abnormal').toString()
      } else if (errorCode === 191) {
        errmsg = this.$t('Error_setting_of_right_driver_parameters').toString()
      } else if (errorCode === 222) {
        errmsg = this.$t('lidar_anomaly').toString()
      } else if (errorCode === 223) {
        errmsg = this.$t('depth_camera_0_abnormal').toString()
      } else if (errorCode === 224) {
        errmsg = this.$t('depth_camera_1_abnormal').toString()
      } else if (errorCode === 225) {
        errmsg = this.$t('depth_camera_2_abnormal').toString()
      } else if (errorCode === 226) {
        errmsg = this.$t('depth_camera_3_abnormal').toString()
      } else if (errorCode === 227) {
        errmsg = this.$t('cooling_fan_abnormal_of_the_control_box').toString()
      }else {
        errmsg = errorCode + ''
      }
      return errmsg
    }
  private setRobotJson () {
    let that = this;
    //故障信息
    if (this.robot.error_code !== undefined && this.robot.error_code.length > 0) {
      let errorMsg = ''
      let errCode:number[] = this.robot.error_code
      errCode.forEach(element => {
        errorMsg += that.geterrmsg(element) + '\n'
      })
      console.log('errorMsg', errorMsg + ' errorMsg.length:' + errorMsg.length)
      this.faultData = errorMsg
    } else {
      this.faultData = ""
    }
    //  else {
    //   this.faultData = this.$t('no_data').toString()
    // }
    const map =
      globalData.mapUrl +
      this.robotId +
      globalData.fileUrl +
      this.robot.map_name +
      "/" +
      this.robot.map_name +
      ".png";
    this.robotMap = map;
    const img = new Image() 
    img.src = map;
    img.onload = function() {
      if(that.orgmapwidth > that.orgmapheight) {
        that.orgmapwidth  = 410
        that.mapwidth = 410
      }
      //图片宽高切换
      if (img.width > img.height) {
        that.angleMap = 90
        // if(that.orgmapheight > 600) {
        //   that.orgmapheight = 600
        // }
        // let tempOheight = that.orgmapheight;
        // that.orgmapheight = that.orgmapwidth;
        // that.orgmapwidth = tempOheight;
      }
      // console.log('orgmapwidth', that.orgmapwidth)
      //let log = "";
      let nowK = that.orgmapwidth / that.orgmapheight;
      let imgk = img.width / img.height;
      //log += "that.mapwidthOOOO :" + that.orgmapwidth  + " that.mapheightOOO:" + that.orgmapheight;
      if (imgk > nowK) {
        that.mapheight = that.mapwidth / imgk;
        // console.log("imgkwidth:" + img.width + "imgkheight:" + img.height + " screen.width:" + screen.width + " screen.height:" + screen.height + " nowK:" + nowK);
      } else {
        that.mapwidth = that.mapheight * imgk;
      }
      //图片宽高切换
      if (that.angleMap === 90) {
        let dist = that.mapwidth - that.mapheight
        that.leftMap = -dist/2
        that.topMap = dist/2
      }
      that.dxHeight = img.height / that.mapheight;
      that.dxWidth = img.width / that.mapwidth;
      //log += "that.mapwidthKKK:" + that.mapwidth  + " that.mapheightKKK:" + that.mapheight + " img.width:" + img.width + " img.height:" + img.height + " imgk:" + imgk + " nowK:" + nowK + " dxHeight:" + that.dxHeight + "dxWidth:" + that.dxWidth;
      //console.log(log)
      if (that.robot.robot_pose != null) {
        if(that.angleMap === 90) {
          that.left = (img.height - that.robot.robot_pose.y) / that.dxHeight - 6;
          that.top = that.robot.robot_pose.x  / that.dxWidth - 6;
          that.angle = that.robot.robot_pose.theta * 180 / Math.PI + 90 + 90;
        } else {
          that.left = that.robot.robot_pose.x  / that.dxWidth - 6;
          that.top = that.robot.robot_pose.y / that.dxHeight - 6;
          that.angle = that.robot.robot_pose.theta * 180 / Math.PI + 90;
        }
        // console.log(
        //   "left:" + that.left + ",top:" + that.top + ",angle:" + that.angle
        // );
      }
      // console.log('that.mapwidth:' + that.mapwidth + 'that.mapwidth:' +  that.mapheight + " img.width:" + img.width + " img.height:" + img.height)
      if (that.canvas != undefined && (that.lastMapWidth !== that.mapwidth || that.lastMapHeight !== that.mapheight)) { //  || that.lastMapHeight !== that.mapheight
        that.Kx = img.width /that.mapwidth
        that.Ky = img.height / that.mapheight
        if(that.angleMap == 90) {
           that.canvasWidth = that.mapheight
          that.canvasHeight = that.mapwidth
        } else {
          that.canvasWidth = that.mapwidth
          that.canvasHeight = that.mapheight
        }
        // if(that.mapwidth < 410) {
        //    that.canvasWidth = 410
        // } else {
        //   that.canvasWidth = that.mapwidth
        // }
        // if (that.mapheight < 410) {
        //   that.canvasHeight = 410
        // } else {
        //   that.canvasHeight = that.mapheight
        // }
        that.lastMapHeight = that.mapheight
        that.lastMapWidth = that .mapwidth
        // if(that.angleMap === 90) {
        //   that.Kx = img.width / that.mapheight
        //   that.Ky = img.height / that.mapwidth
        // } else {
        //   that.Kx = img.width / that.mapwidth
        //   that.Ky = img.height / that.mapheight
        // }
        that.canvas.setAttribute('width', that.canvasWidth)
        that.canvas.setAttribute('height', that.canvasHeight)
      }
    }
  }
  private setRobotJson1 () {
    let that = this;
    const map =
      globalData.mapUrl +
      this.robotId +
      globalData.fileUrl +
      this.robot.map_name +
      "/" +
      this.robot.map_name +
      ".png";
    this.robotMap = map;
    const img = new Image() 
    img.src = map;
    img.onload = function() {
      let log = "";
      let nowK = that.orgmapwidth / that.orgmapheight;
      let imgk = img.width / img.height;
      log += "that.mapwidthOOOO :" + that.orgmapwidth  + " that.mapheightOOO:" + that.orgmapheight;
      if (imgk > nowK) {
        that.mapheight = that.mapwidth / imgk;
        console.log("imgkwidth:" + img.width + "imgkheight:" + img.height + " screen.width:" + screen.width + " screen.height:" + screen.height + " nowK:" + nowK);
      } else {
        that.mapwidth = that.mapheight * imgk;
      }
      that.dxHeight = img.height / that.mapheight;
      that.dxWidth = img.width / that.mapwidth;
      log += "that.mapwidthKKK:" + that.mapwidth  + " that.mapheightKKK:" + that.mapheight + " img.width:" + img.width + " img.height:" + img.height + " imgk:" + imgk + " nowK:" + nowK + " dxHeight:" + that.dxHeight + "dxWidth:" + that.dxWidth;
      console.log(log)
      if (that.robot.robot_pose != null) {
        that.left = that.robot.robot_pose.x  / that.dxWidth - 6;
        that.top = that.robot.robot_pose.y / that.dxHeight - 6;
        that.angle = that.robot.robot_pose.theta * 180 / Math.PI + 90;
        console.log(
          "left:" + that.left + ",top:" + that.top + ",angle:" + that.angle
        );
      }
    }
  }
// private geterrmsg (errorCode:number):string {
//         let errmsg = "";
//         if (errorCode==0 ){
//             errmsg = this.$t("copysuccess");
//         }else if (errorCode==101){
//             errmsg=getString(R.string.Single_chip_no_user);
//         }else if (errorCode==102){
//             errmsg=getString(R.string.other_errors);
//         }else if (errorCode==103){
//             errmsg=getString(R.string.single_chip_errors);
//         }else if (errorCode==104){
//             errmsg=getString(R.string.meter_no_response);
//         }else if (errorCode==105){
//             errmsg=getString(R.string.meter_other_anomalies);
//         }else if (errorCode==106){
//             errmsg=getString(R.string.meter_data_abnormity);
//         }else if (errorCode==107){
//             errmsg=getString(R.string.ui_no_response);
//         }else if (errorCode==108){
//             errmsg=getString(R.string.ui_other_anomalies);
//         }else if (errorCode==109){
//             errmsg=getString(R.string.ui_data_abnormity);
//         }else if (errorCode==201){
//             errmsg=getString(R.string.ui_Control_Module_one);
//         }else if (errorCode==202){
//             errmsg=getString(R.string.ui_Control_Module_two);
//         }else if (errorCode==203){
//             errmsg=getString(R.string.ui_error_one);
//         }else if (errorCode==204){
//             errmsg=getString(R.string.ui_error_two);
//         }else if (errorCode==205){
//             errmsg=getString(R.string.ui_error_three);
//         }else if (errorCode==206){
//             errmsg=getString(R.string.ui_error_four);
//         }else if (errorCode==207){
//             errmsg=getString(R.string.ui_error_five);
//         }else if (errorCode==208){
//             errmsg=getString(R.string.ui_error_six);
//         }else if (errorCode==209){
//             errmsg=getString(R.string.ui_error_seven);
//         }else if (errorCode==210){
//             errmsg=getString(R.string.ui_error_eigth);
//         }else if (errorCode==211){
//             errmsg=getString(R.string.ui_error_nine);
//         }else if (errorCode==212){
//             errmsg=getString(R.string.ui_error_ten);
//         }else if (errorCode==213){
//             errmsg=getString(R.string.ui_error_eleven);
//         }else if (errorCode==214){
//             errmsg=getString(R.string.ui_error_Twelve);
//         }else if (errorCode==215){
//             errmsg=getString(R.string.Abnormal_probe_one_low);
//         }else if (errorCode==216){
//             errmsg=getString(R.string.Abnormal_probe_one_higth);
//         }else if (errorCode==217){
//             errmsg=getString(R.string.Abnormal_probe_two_low);
//         }else if (errorCode==218){
//             errmsg=getString(R.string.Abnormal_probe_two_higth);
//         }else if (errorCode==110){
//             errmsg=getString(R.string.single_chip_com_error);
//         }else if (errorCode==111){
//             errmsg=getString(R.string.single_chip_com_error_other);
//         }else if (errorCode==112){
//             errmsg=getString(R.string.single_chip_com_error_data);
//         }else if (errorCode==113){
//             errmsg=getString(R.string.imu_no_response);
//         }else if (errorCode==114){
//             errmsg=getString(R.string.imu_other_anomalies);
//         }else if (errorCode==115){
//             errmsg=getString(R.string.imu_data_abnormity);
//         }else if (errorCode==116){
//             errmsg=getString(R.string.Handle_exception);
//         }else if (errorCode==119){
//             errmsg=getString(R.string.com_no_find);
//         }else if (errorCode==120){
//             errmsg=getString(R.string.com_no_data);
//         }else if (errorCode==122){
//             errmsg=getString(R.string.six_line_no_find);
//         }else if (errorCode==123){
//             errmsg=getString(R.string.six_line_no_data);
//         }else if (errorCode==124){
//             errmsg=getString(R.string.Horizontal_radar_no_find);
//         }else if (errorCode==125){
//             errmsg=getString(R.string.Horizontal_radar_no_data);
//         }else if (errorCode==126){
//             errmsg=getString(R.string.Oblique_downward_radar_no_find);
//         }else if (errorCode==127){
//             errmsg=getString(R.string.Oblique_downward_radar_no_data);
//         }else if (errorCode==134){
//             errmsg=getString(R.string.Left_drive_control_exception);
//         }else if (errorCode==135){
//             errmsg=getString(R.string.Left_Driver_Contact_Abnormality);
//         }else if (errorCode==136){
//             errmsg=getString(R.string.Left_Driver_Overflow);
//         }else if (errorCode==137){
//             errmsg=getString(R.string.Right_Driver_Control_Exception);
//         }else if (errorCode==138){
//             errmsg=getString(R.string.Right_Driver_Contact_Abnormality);
//         }else if (errorCode==139){
//             errmsg=getString(R.string.Right_Driver_Overcurrent);
//         }else if (errorCode==140){
//             errmsg=getString(R.string.Left_push_rod_not_returned_to_position);
//         }else if (errorCode==141){
//             errmsg=getString(R.string.Left_push_rod_is_not_put_in_place);
//         }else if (errorCode==142){
//             errmsg=getString(R.string.right_push_rod_not_returned_to_position);
//         }else if (errorCode==143){
//             errmsg=getString(R.string.right_push_rod_is_not_put_in_place);
//         }else if (errorCode==150){
//             errmsg=getString(R.string.Front_Camera_no_Find_Device);
//         }else if (errorCode==151){
//             errmsg=getString(R.string.Front_camera_has_no_data);
//         }else if (errorCode==152){
//             errmsg=getString(R.string.Back_Camera_no_Find_Devices);
//         }else if (errorCode==153){
//             errmsg=getString(R.string.Back_camera_has_no_data);
//         }else if (errorCode==160){
//             errmsg=getString(R.string.Left_drive_overcurrent);
//         }else if (errorCode==161){
//             errmsg=getString(R.string.Left_drive_overload);
//         }else if (errorCode==162){
//             errmsg=getString(R.string.Left_motor_feedback_signal_error);
//         }else if (errorCode==163){
//             errmsg=getString(R.string.Left_drive_overvoltage);
//         }else if (errorCode==164){
//             errmsg=getString(R.string.Left_motor_undervoltage);
//         }else if (errorCode==165){
//             errmsg=getString(R.string.Left_drive_overheating);
//         }else if (errorCode==166){
//             errmsg=getString(R.string.Failure_to_start_left_motor);
//         }else if (errorCode==167){
//             errmsg=getString(R.string.Left_Driver_EEP_Data_Error);
//         }else if (errorCode==168){
//             errmsg=getString(R.string.Left_motor_overheating);
//         }else if (errorCode==169){
//             errmsg=getString(R.string.Left_motor_overspeed);
//         }else if (errorCode==170){
//             errmsg=getString(R.string.Left_Drive_ENCODE_Error);
//         }else if (errorCode==171){
//             errmsg=getString(R.string.Initial_operation_of_left_motor_prohibited);
//         }else if (errorCode==172){
//             errmsg=getString(R.string.Left_motor_external_stop);
//         }else if (errorCode==173){
//             errmsg=getString(R.string.Left_Driver_Hall_Sequence_Error);
//         }else if (errorCode==174){
//             errmsg=getString(R.string.Left_Driver_Communication_Instruction_Abnormal);
//         }else if (errorCode==175){
//             errmsg=getString(R.string.Left_Driver_Parameter_Setting_Error);
//         }else if (errorCode==176){
//             errmsg=getString(R.string.Right_Driver_Overcurrent);
//         }else if (errorCode==177){
//             errmsg=getString(R.string.Right_Driver_Overload);
//         }else if (errorCode==178){
//             errmsg=getString(R.string.Right_motor_feedback_signal_error);
//         }else if (errorCode==179){
//             errmsg=getString(R.string.Right_Driver_Overvoltage);
//         }else if (errorCode==180){
//             errmsg=getString(R.string.Right_motor_undervoltage);
//         }else if (errorCode==181){
//             errmsg=getString(R.string.Right_Horse_Driver_Overheating);
//         }else if (errorCode==182){
//             errmsg=getString(R.string.Failure_of_startup_of_right_motor);
//         }else if (errorCode==183){
//             errmsg=getString(R.string.Right_Driver_EEP_Data_Error);
//         }else if (errorCode==184){
//             errmsg=getString(R.string.Right_motor_overheating);
//         }else if (errorCode==185){
//             errmsg=getString(R.string.Right_motor_overspeed);
//         }else if (errorCode==186){
//             errmsg=getString(R.string.Right_Driver_ENCODE_Error);
//         }else if (errorCode==187){
//             errmsg=getString(R.string.Initial_operation_of_right_motor_prohibited);
//         }else if (errorCode==188){
//             errmsg=getString(R.string.driving_wheel_disable);
//         }else if (errorCode==189){
//             errmsg=getString(R.string.Right_Driver_Hall_Sequence_Error);
//         }else if (errorCode==190){
//             errmsg=getString(R.string.Right_Driver_Communication_Instruction_Abnormal);
//         }else if (errorCode==191){
//             errmsg=getString(R.string.Error_setting_of_right_driver_parameters);
//         }else {
//             errmsg = errorCode + "";
//         }
//         return errmsg;
//     }
  private async getRobotDetail(robotId: string) {
    let req: GetRobotByIdReq = {
      robotId: robotId,
    };
    let res: GetRobotByIdRes = await getRobotById(req);
    console.log(res);
    if (res.code == 0) {
      console.log(res.robot);
      if (res.robot.robotEntity.stateText) {
        let json = await jsonParse("" + res.robot.robotEntity.stateText).catch(() => {
          console.log();
        });
        this.robot = json;
        this.setRobotJson();
      }
      if (res.robot.robotEntity.routerText) {
            let routerInfo = JSON.parse(res.robot.robotEntity.routerText)
            this.routerInfo = routerInfo;
          }
    }
  }
  private setTime() {
    this.showDateTime = true;
  }
  private awakeOrSleep () {
    if(this.routerInfo.is_robot_on) {
      powerOff(this.robotId);
      this.routerInfo.router_state = -1
    } else {
      powerON(this.robotId);
    }
  }

  private confirmDateTime() {
    this.showDateTime = false;
    // this.timeDate = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    this.setMaintainInfoK(formatDate(this.currentDate.getTime() / 1000, "-", ":"))
  }

  private cancelDateTime() {
    this.showDateTime = false;
  }

  private destroyed() {
    EventBus.$off("robotDetail");
    MqttClientM.getInstance().unSubscribeRobot(this.robotId);
    MqttClientM.getInstance().unSubscribeResponse(this.robotId);
    MqttClientM.getInstance().unSubscribeFeedback(this.robotId);
    MqttClientM.getInstance().unSubscribeRouterInfo(this.robotId);
    stopService();
    this.removeTouchListener();
  }
}
